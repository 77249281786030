import icons from '../Student/icons/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEURL } from '../../constants';
import { truncateText } from '../Student/util/formatting';

const CourseDetails = () => {

    const [hierarchy, setHierarchy] = useState(null);
    const [courseInfo, setCourseInfo] = useState(null);
    const { courseId, sharedCourseId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axios.post(`${BASEURL}/api/update-course-completion-percentage`, {
            courseId: sharedCourseId,
            masterCourseId: courseId,
        }).then((response) => {
            console.log("response", response);
        })
    }, [])

    useEffect(() => {
        const fetchSidebarHierarchy = axios.get(`${BASEURL}/api/get-sidebar-hierarchy`, {
            params: {
                courseId: courseId,
            },
        })

        fetchSidebarHierarchy.then((response) => {

            axios.get(`${BASEURL}/api/fetch-course-info`, {
                params: {
                    courseId: courseId,
                },
            }).then((res) => {
                console.log("course info ", res)

                const fetchedSidebarData = response.data;

                if (fetchedSidebarData.topics.length) {
                    console.log("fetchedSidebarData", fetchedSidebarData)
                    setHierarchy(fetchedSidebarData);
                } else {
                    setHierarchy({ topics: [] })
                }

                setCourseInfo(res.data.rows)
            })
        })

    }, [])

    return (
        courseInfo ? (
            <div className="h-dvh w-full box-border flex flex-col">
                <div className='flex gap-5 items-center mb-5'>
                    <button className='inline-flex truncate justify-center items-center rounded-md bg-blue-500 pr-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ' onClick={() => { navigate('/student/myCourses') }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                        <span className='text-sm'>Back</span>
                    </button>
                    <span className='font-bold text-lg'>{courseInfo ? courseInfo.course_name : 'Know Basics of Noun'}</span>
                </div>
                <div className='mb-5'>
                    <h3 className='font-bold mb-1 text-lg'>About course</h3>
                    {
                        courseInfo ? truncateText(courseInfo.course_desc) : 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci sapiente magni porro blanditiis nam qui deleniti architecto non illum, explicabo vitae, mollitia minima nobis atque? Labore modi eligendi optio debitis.'
                    }
                </div>
                <div className='flex gap-10 w-max px-3 py-3' style={{ border: 'solid 1px #fc885e', borderRadius: '20px', boxShadow: '0 6px 16px -3px rgba(106, 90, 224, 0.12);', backgroundImage: 'linear-gradient(to top, #f4b858 -39%, #fe7d5f 115%)' }}>
                    <span className='flex flex-col '>
                        <span>{courseInfo ? courseInfo.slide_count : '60'}</span>
                        <span>Slides</span>
                    </span>
                    <span className='flex flex-col'>
                        <span>{courseInfo ? (courseInfo.video_count ? courseInfo.video_count : '0') : '10'}</span>
                        <span>Videos</span>
                    </span>
                    <span className='flex flex-col'>
                        <span>{courseInfo ? (courseInfo.quiz_count ? courseInfo.quiz_count : '0') : '10'}</span>
                        <span>Quiz</span>
                    </span>
                    <span className='flex flex-col'>
                        <span>{courseInfo ? courseInfo.test_count : '60'}</span>
                        <span>Test</span>
                    </span>
                </div>
                <div>
                    <h3 className='font-bold text-lg'>Topics</h3>
                    <div className='flex flex-col justify-start'>
                        {
                            hierarchy && (
                                hierarchy.topics.map((topic, index) => {
                                    return (
                                        <div key={index} className='my-5 w-max' style={{ backgroundColor: '#393185' }}>
                                            <div className='p-2 text-white flex items-center gap-3'>
                                                <div className='px-3 my-2 text-black' style={{ backgroundColor: '#e6e3fa' }}>
                                                    {`${index + 1}`}
                                                </div>
                                                <div>
                                                    {`${topic.name}`}
                                                </div>
                                            </div>
                                            <div className='p-2 ' style={{ backgroundColor: '#fcfbfd', border: 'solid 1px #e1deff' }}>
                                                {topic.content.map((contentObj) => {
                                                    return (
                                                        <div className='flex items-center gap-2'>
                                                            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#ffc99f' }}>

                                                            </div>
                                                            <div className='text-bold'>{contentObj.name}</div>
                                                            <div></div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                </div>
                <div className='sticky bottom-3 text-right'>
                    <Link to={`/CourseConsumption/${courseId}/${sharedCourseId}`} >
                        <button className='p-3 rounded-xl text-white' style={{ backgroundColor: '#fa9248' }}>Learn more</button>
                    </Link>
                </div>

            </div>
        ) : (
            <div>
                fetching course details...
            </div>
        )

    )
    return (
        <div className="h-dvh w-full box-border flex flex-col">
            <section className='w-full h-44 px-2 relative bg-gray-400 before:-z-10 before:block before:absolute before:-inset-1 before:w-10 before:h-10 before:top-full before:bg-gray-400
            after:-z-10 after:block after:absolute after:-inset-1 after:w-10 after:h-10 after:top-full after:left-full after:-translate-x-10 after:bg-gray-400 text-white' style={{ backgroundImage: 'linear-gradient(to bottom , #393185, #7570abae 68%,#f7f8fc00)' }}>
                <div className='flex items-center relative h-10'>
                    <button onClick={() => { navigate('/student/myCourses') }}>
                        {icons.chevronLeft}
                    </button>
                    <span className='absolute left-1/2 font-bold text-base truncate' style={{ transform: 'translate(-50%)' }}>{courseInfo ? courseInfo.course_name : 'Know Basics of Noun'}</span>
                </div>
                <div className='flex justify-center gap-10 w-11/12 mt-4 mb-4 text-white py-5 absolute top-3/4 left-1/2' style={{ transform: 'translate(-50% , -50%)', border: 'solid 1px #fc885e', borderRadius: '20px', boxShadow: '0 6px 16px -3px rgba(106, 90, 224, 0.12);', backgroundImage: 'linear-gradient(to top, #f4b858 -39%, #fe7d5f 115%)' }}>
                    <span className='flex flex-col '>
                        <span>{courseInfo ? courseInfo.slide_count : '60'}</span>
                        <span>Slides</span>
                    </span>
                    <span className='flex flex-col'>
                        <span>{courseInfo ? (courseInfo.video_count ? courseInfo.video_count : '0') : '10'}</span>
                        <span>Videos</span>
                    </span>
                    <span className='flex flex-col'>
                        <span>{courseInfo ? (courseInfo.quiz_count ? courseInfo.quiz_count : '0') : '10'}</span>
                        <span>Quiz</span>
                    </span>
                    <span className='flex flex-col'>
                        <span>{courseInfo ? courseInfo.test_count : '60'}</span>
                        <span>Test</span>
                    </span>
                </div>
                {/* <div className='h-20 flex justify-center'>
                    <img src={bgImage} className='w-40 h-20 rounded-t-lg'></img>
                </div> */}
            </section>
            <section className='p-4 rounded-tl-2xl rounded-tr-2xl bg-white grow overflow-auto' style={{ backgroundColor: '#f5f4fd' }}>
                <div className='mt-8'>
                    <div className='rounded-xl p-4 '>
                        <h3 className='font-bold mb-1 text-lg'>About course</h3>
                        {
                            courseInfo ? truncateText(courseInfo.course_desc) : 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci sapiente magni porro blanditiis nam qui deleniti architecto non illum, explicabo vitae, mollitia minima nobis atque? Labore modi eligendi optio debitis.'
                        }
                    </div>
                    <div className='p-4'>
                        <h3 className='font-bold text-lg'>Topics</h3>
                        {
                            hierarchy && (
                                hierarchy.topics.map((topic, index) => {
                                    return (
                                        <div key={index} className='my-5 rounded-xl' style={{ backgroundColor: '#393185' }}>
                                            <div className='p-2 text-white flex items-center gap-3'>
                                                <div className='px-3 my-2 rounded-md text-black' style={{ backgroundColor: '#e6e3fa' }}>
                                                    {`${index + 1}`}
                                                </div>
                                                <div>
                                                    {`${topic.name}`}
                                                </div>
                                            </div>
                                            <div className='p-2  rounded-md' style={{ backgroundColor: '#fcfbfd', border: 'solid 1px #e1deff' }}>
                                                {topic.content.map((contentObj) => {
                                                    return (
                                                        <div className='flex items-center gap-2'>
                                                            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#ffc99f' }}>

                                                            </div>
                                                            <div className='text-bold'>{contentObj.name}</div>
                                                            <div></div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        }
                        <div className='sticky bottom-0'>
                            <Link to={`/CourseConsumption/${courseId}/${sharedCourseId}`} >
                                <button className='p-3 rounded-xl text-white' style={{ backgroundColor: '#fa9248' }}>Learn more</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CourseDetails;