const Slide = ({currentSlideData}) => {
    
    
    return (
        <div id='mobile-slide' className={`bg-white w-full grow flex flex-col
            border border-solid border-slate-300 relative p-2
            overflow-auto gap-2 h-20 `}

        >
            {/* <button className='cta__button' style={{position:'absolute' , top:'4px' , right:'3px'}} >Mark finish</button> */}
            {
                currentSlideData.content.map((contentObj) => {
                    if (!contentObj.data) return;
                    if (contentObj.type === "Heading") {
                        return (
                            <div className='copy-ql-editor'>
                                <span className="heading_from_top" style={{ fontSize: "20px", fontWeight: '400', lineHeight: '145%', width: '100%', color: 'black' }}>{contentObj.data}</span>
                            </div>
                        )
                    }
                    if (contentObj.type === "Text") {
                        return (
                            <div className='ql-snow'>
                                <div dangerouslySetInnerHTML={{ __html: contentObj.data }} className='ql-editor'>

                                </div>
                            </div>
                        )
                    }
                    if (contentObj.type === "Quiz") {
                        console.log('url ', contentObj.data.imageData.image);
                        return (
                            <div className='' style={{ fontSize: '1.5em' }}>
                                <div>
                                    <span>{`Q) `}</span>
                                    <span>{contentObj.data.question}</span><br />
                                    <div style={{ width: "100%", display: "flex", justifyContent: "start", paddingLeft: '22px', justifyContent: 'space-between' }}>
                                        {contentObj.data.imageData.image && (
                                            <div style={{ width: contentObj.data.imageData.width ? contentObj.data.imageData.width : 'auto', height: contentObj.data.imageData.height ? contentObj.data.imageData.height : 'auto' }}>
                                                <img id={"imgId"} src={contentObj.data.imageData.image} style={{ height: "100%", width: "100%" }}></img>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {
                                            contentObj.data.type === 'single' ? (
                                                contentObj.data.options.map((option, optIndex) => {
                                                    return (
                                                        <div style={{ marginLeft: '22px', paddingTop: "0.2em" }}>
                                                            <input type='radio' style={{ marginRight: "3px" }} value={option} id={`option${optIndex}`} name={`reord`}></input>
                                                            <span>{`${String.fromCharCode(65 + optIndex)})`}</span>
                                                            <label for={`option${optIndex}`}>{option}</label>
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                contentObj.data.options.map((option, optIndex) => {
                                                    return (
                                                        <div style={{ paddingTop: "0.2em" }}>
                                                            <input type='checkbox' style={{ marginRight: "3px" }} value={option} id={`option${optIndex}`} name={`reord`}></input>
                                                            <span>{`${String.fromCharCode(65 + optIndex)})`}</span>
                                                            <label for={`option${optIndex}`}>{option}</label>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        )
                    }
                    if (contentObj.type === "Image") {
                        return (
                            <div style={{ width: "100%", display: "flex", justifyContent: contentObj.data.align ? contentObj.data.align : 'center' }}>
                                {contentObj.data.imgData && (
                                    <div>
                                        <img id={"imgId"} src={contentObj.data.imgData} style={{ height: "100%", width: "100%" }}></img>
                                    </div>
                                )}
                            </div>
                        )
                    }
                    // style={{ width: contentObj.data.width ? contentObj.data.width : 'auto', height: contentObj.data.height ? contentObj.data.height : 'auto' }}
                    if (contentObj.type === "Video") {
                        return (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {contentObj.data.ytData.videoId &&
                                    <iframe
                                        className='iframeHeight'
                                        width="50%"
                                        height={'400px'}
                                        src={`https://www.youtube.com/embed/${contentObj.data.ytData.videoId}?rel=0`}
                                        title="YouTube Video"
                                        frameBorder="0"
                                        allowFullScreen={true}
                                    ></iframe>
                                }
                            </div>
                        )
                    }
                })
            }
        </div>
    );
}

export default Slide;