import { useEffect, useState } from "react";
import YouTubeVideo from "./YouTubeVideo";
import PcVideo from "./PcVideo";

const VideoComponent = ({ setCurrentSlideData, slidesArray, setSlidesArray, slideId, contentId, data, isSorted, setIsDataSaved }) => {

    const [videoData, setVideoData] = useState({
        renderComponent: "choiceComponent",
        ytData: {
            videoUrl: "",
            videoId: ""
        }
    })
    const [reRenderChid, setReRenderChild] = useState(false);
    //when videoComponents parent re-renders then videoState is lost 
    useEffect(() => {
        if (data) {
            setVideoData(data);
        } else {
            setVideoData({
                renderComponent: "choiceComponent"
                // ytData: {
                //     videoUrl: "",
                //     videoId: ""
                // }
            })
        }
        setReRenderChild(!reRenderChid);
    }, [slideId, isSorted])

    useEffect(() => {
        console.log("setting current Slide data ", "videoData", videoData)

        setCurrentSlideData((currentSlide) => {
            return {
                ...currentSlide, content: currentSlide.content.map((contentObject) => {
                    if (contentObject.id === contentId) {
                        return {
                            id: contentObject.id,
                            type: contentObject.type,
                            data: videoData
                        }
                    }
                    return {
                        ...contentObject
                    }
                })
            }
        })

    }, [videoData])


    if (videoData.renderComponent === "choiceComponent") {
        return (
            <div className="flex flex-col justify-center border rounded-xl items-center gap-4">
                <div className="d-flex cursor-pointer flex-column justify-content-center align-items-center p-3" onClick={() => {
                    setIsDataSaved(false); setVideoData((prevVideoData) => {
                        const newVideoData = { ...prevVideoData, renderComponent: "pc_video_component" };
                        return newVideoData;
                    })
                }}>
                    Browse from device
                </div>
                <div> OR </div>
                <div className="yt_video flex flex-col justify-center items-center p-3" style={{ cursor: 'pointer' }} onClick={() => {
                    setIsDataSaved(false); setVideoData((prevVideoData) => {
                        const newVideoData = { ...prevVideoData, renderComponent: "yt_component" };
                        return newVideoData;
                    })
                }}>
                    YouTube video
                </div>
            </div>
        )
    }
    if (videoData.renderComponent === "yt_component") {
        return (
            <YouTubeVideo videoData={videoData} setVideoData={setVideoData} reRenderChid={reRenderChid} />
        )
    }

    if (videoData.renderComponent === "pc_video_component") {
        return <PcVideo setCurrentSlideData={setCurrentSlideData} videoData={videoData} setVideoData={setVideoData} reRenderChid={reRenderChid} contentId={contentId} />
    }
}

export default VideoComponent;