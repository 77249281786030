import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import NewPreviewSideBar from "./NewPreviewSideBar";
import NewPreviewCourseCreator from "./NewPreviewCourseCreator";
import axios from "axios";
import { BASEURL } from '../../../../constants';
import NewPreviewTestComponent from "./NewPreviewTestComponent";

function NewPreviewComponent() {

    const [contentArray, setContentArray] = useState(null);
    const [contentIndex, setContentIndex] = useState(null);

    const [currentSlideData, setCurrentSlideData] = useState(null);
    const [slidesArray, setSlidesArray] = useState(null);
    const [currentSlideId, setCurrentSlideId] = useState(null); // will contain the id of the current slide

    const [questionsArray, setQuestionsArray] = useState(null);
    const [question, setQuestion] = useState(null);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [testName, setTestName] = useState('');
    const [numberOfQuestions, setNumberOfQuestions] = useState(null);
    const [testTimeHours, setTestTimeHours] = useState(null);
    const [testTimeMinutes, setTestTimeMinutes] = useState(null);


    const [selectedTestId, setSelectedTestId] = useState(null);
    const [selectedChapterId, setSelectedChapterId] = useState(null);

    const [sidebarData, setSidebarData] = useState(null); //i lifted it for breadcrum 

    const [loading, setLoading] = useState(false);
    const [previous, setPrevious] = useState(false);
    const [imageIdArray, setImageIdArray] = useState([]); //store the fetched images 
    // data of a single semester {semesters : [ { id , name , chapters , content } ]}
    const [highlight, setHighlight] = useState(``);

    const [type, setType] = useState('');

    //cousre info to pass to course creator
    const { courseId } = useParams();
    console.log('courseId ', courseId)

    useEffect(() => {
        axios.get(`${BASEURL}/api/get-sidebar-hierarchy`, {
            params: {
                courseId: courseId
            }
        }).then((response) => {
            console.log(response.data);
            /**
             * if there are not topics added 
             * backend will return { topics : [ ] };
             */
            const sidebarHierarchy = response.data;
            setSidebarData(sidebarHierarchy);

            if (sidebarHierarchy.topics.length) {
                if (sidebarHierarchy.topics[0].content.length) {
                    if (sidebarHierarchy.topics[0].content[0].type === 'slide-group') {
                        setSelectedChapterId(sidebarHierarchy.topics[0].content[0].id);
                    }

                    if (sidebarHierarchy.topics[0].content[0].type === 'test') {
                        setSelectedTestId(sidebarHierarchy.topics[0].content[0].id)
                    }
                }

                /*
                 * creating a contentArray  
                    for jumping slide-groups and tests 
                 */
                let contentArray = [];
                sidebarHierarchy.topics.forEach((topic) => {
                    /* 
                      topic ->  { id , name , content : [] }
                    */
                    if (topic.content.length) {
                        topic.content.forEach((contentObject) => {
                            /* 
                                contentObject -> { id , type , sequence , name }
                            */
                            contentArray.push(contentObject);
                        })
                    }
                })

                if (contentArray.length) {
                    console.log('contentArray ', contentArray)
                    setContentArray(contentArray);
                    setContentIndex(0);
                } else {
                    setContentArray([]);
                }
            }
        })
    }, [])

    useEffect(() => {
        if (sidebarData) {
            if (selectedChapterId) {

                axios.get(`${BASEURL}/api/get-sub-topic-data`, {
                    params: {
                        subTopicId: selectedChapterId
                    }
                }).then((response) => {
                    console.log('response  ', response);
                    //----------------------------------------------------
                    const slidesArray = response.data;
                    const arrayOfImageIdArray = slidesArray.map((slideObject, index) => {
                        if (slideObject.imageIdArray) {
                            return slideObject.imageIdArray;
                        } else {
                            return []
                        }
                    })

                    const imageKeysArray = arrayOfImageIdArray.reduce((acc, item) => acc.concat(item));

                    if (imageKeysArray.length) {
                        const imagesPromiseArray = imageKeysArray.map((key, index) => {
                            return axios.get(`${BASEURL}/api/get-image`, {
                                params: {
                                    imageId: key,
                                    index: index
                                }
                            })
                        })
                        //let single = arr.reduce((elem1, elem2) => elem1.concat(elem2));
                        //the response we will get will have { id , index , dataURL }
                        Promise.all(imagesPromiseArray)
                            .then((imageResponse) => {
                                //response will be array of array ? [ { data : { dataUrl : '' } } {} ]
                                console.log('imageResponse', imageResponse);
                                imageResponse.forEach((resObject, index) => {
                                    const imageUrl = resObject.data.dataUrl;
                                    const contentObjectId = imageKeysArray[index];

                                    //how to find slideIndex 
                                    //the slide where the key is present in the imageIdArray 
                                    const slideIndex = slidesArray.findIndex(slide => slide.imageIdArray.includes(contentObjectId));
                                    //find the contentObjectIndex 
                                    const targetSlide = slidesArray[slideIndex];
                                    const contentObjectIndex = targetSlide.content.findIndex(contentObject => contentObject.id === contentObjectId);

                                    if (slidesArray[slideIndex].content[contentObjectIndex].type === 'Image') {
                                        slidesArray[slideIndex].content[contentObjectIndex].data.imgData = imageUrl;

                                    }
                                    if (slidesArray[slideIndex].content[contentObjectIndex].type === 'Quiz') {
                                        slidesArray[slideIndex].content[contentObjectIndex].data.imageData.image = imageUrl;
                                    }
                                })
                                setSlidesArray(slidesArray);
                                if (previous) {
                                    setPrevious(false);
                                    setCurrentSlideId(slidesArray[slidesArray.length - 1].id);
                                    setCurrentSlideData(slidesArray[slidesArray.length - 1]);
                                } else {
                                    setCurrentSlideId(slidesArray[0].id);
                                    setCurrentSlideData(slidesArray[0]);
                                }

                            })
                    } else {
                        setSlidesArray(slidesArray);
                        if (previous) {
                            setPrevious(false);
                            setCurrentSlideId(slidesArray[slidesArray.length - 1].id);
                            setCurrentSlideData(slidesArray[slidesArray.length - 1]);
                        } else {
                            setCurrentSlideId(slidesArray[0].id);
                            setCurrentSlideData(slidesArray[0]);
                        }
                    }
                })
            } else {
                //this will execute on mount as well as on subtopic delete
                setSlidesArray(null);
                setCurrentSlideData(null);
                setCurrentSlideId(null);
            }

            if (selectedTestId) {
                axios.get(`${BASEURL}/api/get-test-data`, {
                    params: {
                        testId: selectedTestId
                    }
                }).then((response) => {

                    axios.get(`${BASEURL}/api/get-test-duration`, {
                        params: {
                            testId: selectedTestId
                        }
                    }).then((getDurationResponse) => {
                        //response should be questionsArray 
                        //well we are not setting anything here 
                        const questionsArray = response.data;
                        const { testTimeHours, testTimeMinutes, numberOfQuestions } = getDurationResponse.data[0];

                        //[{ } , { } ]
                        const imagekeyArray = questionsArray.map((question) => {
                            if (question.image_data.image) {
                                return question.image_key
                            }
                        }).filter((key) => key)

                        const imagePromisesArray = imagekeyArray.map((key) => {
                            if (key) {
                                return axios.get(`${BASEURL}/api/get-image`, {
                                    params: {
                                        imageId: key
                                    }
                                })
                            }
                        })

                        Promise.all(imagePromisesArray)
                            .then((imagesResponse) => {

                                imagesResponse.forEach((responseObj, index) => {
                                    const key = imagekeyArray[index];
                                    const questionIndex = questionsArray.findIndex(question => question.image_key === key);
                                    questionsArray[questionIndex] = { ...questionsArray[questionIndex], image_data: { ...questionsArray[questionIndex].image_data, image: responseObj.data.dataUrl } }
                                })
                                setQuestionsArray(questionsArray);
                                if (previous) {
                                    setPrevious(false);
                                    setQuestion(questionsArray[questionsArray.length - 1]);
                                    setCurrentQuestionId(questionsArray[questionsArray.length - 1].id);
                                } else {
                                    setQuestion(questionsArray[0]);
                                    setCurrentQuestionId(questionsArray[0].id);
                                }
                                setNumberOfQuestions(numberOfQuestions ? numberOfQuestions : 0);
                                setTestTimeHours(testTimeHours ? testTimeHours : 0);
                                setTestTimeMinutes(testTimeMinutes ? testTimeMinutes : 0);
                            })
                    })
                })
            } else {
                setQuestionsArray(null);
                setQuestion(null);
                setCurrentQuestionId(null);
            }
        }
    }, [selectedChapterId, selectedTestId, type])

    return (
        <>
            {

                sidebarData && (
                    <>
                        <NewPreviewSideBar
                            sidebarData={sidebarData}
                            setSidebarData={setSidebarData}
                            highlight={highlight}
                            setHighlight={setHighlight}
                            setSelectedChapterId={setSelectedChapterId}
                            setSelectedTestId={setSelectedTestId}
                            setTestName={setTestName}
                            selectedChapterId={selectedChapterId}
                            selectedTestId={selectedTestId}
                            contentArray={contentArray}
                            setContentIndex={setContentIndex}
                        />
                        {
                            selectedTestId ? (
                                <NewPreviewTestComponent key={selectedTestId}
                                    questionsArray={questionsArray}
                                    question={question}
                                    currentQuestionId={currentQuestionId}
                                    testName={testName}
                                    selectedTestId={selectedTestId}
                                    setQuestion={setQuestion}
                                    setQuestionsArray={setQuestionsArray}
                                    setCurrentQuestionId={setCurrentQuestionId}
                                    contentArray={contentArray}
                                    contentIndex={contentIndex}
                                    setSelectedChapterId={setSelectedChapterId}
                                    setSelectedTestId={setSelectedTestId}
                                    setContentIndex={setContentIndex}
                                    setPrevious={setPrevious}
                                />
                            ) : (
                                <NewPreviewCourseCreator key={selectedChapterId}
                                    previous={previous}
                                    setPrevious={setPrevious}
                                    currentSlideId={currentSlideId}
                                    setCurrentSlideId={setCurrentSlideId}
                                    setHighlight={setHighlight}
                                    type={type}
                                    slidesArray={slidesArray}
                                    currentSlideData={currentSlideData}
                                    setCurrentSlideData={setCurrentSlideData}
                                    setSlidesArray={setSlidesArray}
                                    contentArray={contentArray}
                                    contentIndex={contentIndex}
                                    setSelectedChapterId={setSelectedChapterId}
                                    setSelectedTestId={setSelectedTestId}
                                    setContentIndex={setContentIndex}
                                />

                            )
                        }
                    </>
                )
            }

        </>
    )
}

export default NewPreviewComponent;


