import React, { useEffect, useState } from 'react';

const YouTubeVideo = ({ videoData, setVideoData, reRenderChid }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoId, setVideoId] = useState('');

  const getVideoId = (url) => {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : '';
  };

  const handleInputChange = (e) => {
    const inputUrl = e.target.value;
    setVideoUrl(inputUrl);

  };

  function handleYtURLUpload() {
    const id = getVideoId(videoUrl);
    setVideoId(id);
  }

  useEffect(() => {
    setVideoData({ ...videoData, ytData: { ...videoData.ytData, videoUrl: videoUrl, videoId: videoId } });
  }, [videoId, videoUrl])

  useEffect(() => {
    setVideoUrl(videoData.ytData?.videoUrl);
    setVideoId(videoData.ytData?.videoId);
  }, [reRenderChid])

  return (
    <div className='p-5'>
      <label className='text-sm flex items-center gap-1'>
        Enter YouTube Video URL:
        <input
          className='text-sm border p-1 rounded-xl px-2'
          type="text"
          value={videoUrl}
          onChange={handleInputChange}  
          placeholder="https://www.youtube.com/watch?v=VIDEO_ID"
        />
        <button className='px-3 py-1 border border-solid border-slate-400 rounded-md text-white bg-slate-500' onClick={handleYtURLUpload}>upload</button>
      </label>
      {videoId && (
        <div className='flex justify-center mt-2'>
          <iframe
            className='w-2/5 h-60 2xl:h-96'
            src={`https://www.youtube.com/embed/${videoId}?rel=0`}
            title="YouTube Video"
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default YouTubeVideo;
