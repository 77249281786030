import ReactECharts from 'echarts-for-react';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { BASEURL } from '../../constants.js'
import axios from "axios";

const AdminHome = () => {
    const token = localStorage.getItem('auth');
    const decoded = jwtDecode(token);

    const { userId, schoolId } = useParams();
    console.log(userId, 'userId')
    console.log(schoolId, 'schoolId')

    const [userCounts, setUserCounts] = useState({
        total: {
            students: 0,
            creators: 0,
            mentors: 0,
        },
        monthly: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const fetchUserCounts = async () => {
            console.log("Fetching user counts for schoolId:", schoolId);
            try {
                const response = await axios.get(`${BASEURL}/api/fetch-users-count/${schoolId}`);
                console.log("User counts fetched successfully:", response.data);
                setUserCounts(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching user counts:", error);
                setError('Error fetching user counts');
                setLoading(false);
            }
        };
    
        fetchUserCounts();
    }, [schoolId]);

    

    const generateChartOption = (role) => {
        // Ensure we have data for all 12 months
        const allMonths = Array.from({length: 12}, (_, i) => i + 1);
        const monthlyData = allMonths.map(month => {
            const monthData = userCounts.monthly.find(item => item.month === month) || { [role]: 0 };
            return monthData[role];
        });
    
        // Define colors for each role
        const colors = {
            creators: '#5470C6',
            students: '#91CC75',
            mentors: '#FAC858'
        };
    
        return {
            tooltip: {},
            xAxis: {
                type: 'category',
                data: allMonths.map(month => `Month ${month}`),
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false }
            },
            yAxis: {
                type: 'value',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false },
                splitLine: { show: false }
            },
            series: [
                {
                    type: 'bar',
                    data: monthlyData,
                    itemStyle: {
                        color: colors[role]
                    }
                }
            ],
            title: { show: false },
            grid: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10,
                containLabel: true
            }
        };
    };
    
    
    // Usage in your component:
    const creatorOption = generateChartOption('creators');
    const studentOption = generateChartOption('students');
    const mentorOption = generateChartOption('mentors');

    const optionTwo = {
        title: {
            text: 'School Data',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: [
                    { value: userCounts.total.creators, name: 'Creators' },
                    { value: userCounts.total.students, name: 'Students' },
                    { value: userCounts.total.mentors, name: 'Mentors' },
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };



    // const { schoolId } = useParams();
    const [schoolDetails, setSchoolDetails] = useState(null);

    useEffect(() => {
        // Fetch school details using schoolId
        axios
            .get(`${BASEURL}/api/fetch-school-details/${schoolId}`)
            .then((response) => {
                setSchoolDetails(response.data.schoolDetails);
            })
            .catch((error) => {
                console.error("Error fetching school details:", error);
            });
    }, [])




    const replacePlaceholders = (string, dataObject) => {
        return string.replace(/{(\w+)}/g, (match, key) => dataObject[key] || "N/A");
    };

    const [selectedDetails, setSelectedDetails] = useState('schoolDetails');

    const handleDetailsChange = (details) => {
        setSelectedDetails(details);
    };

    
    const formatClassIds = (classIds) => {
        if (!classIds || classIds.trim() === '') {
            return 'N/A';
        }
        try {
            const formattedIds = classIds.split(',')
                .map(id => id.trim())
                .filter(id => id !== '') // Remove any empty strings
                .join(', ');
            return formattedIds || 'N/A'; // If we end up with an empty string after processing, return 'N/A'
        } catch (error) {
            console.error('Error formatting class IDs:', error);
            return 'N/A';
        }
    };

    return (
        <>
            {/* <div className="mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 py-2">
            <div className='bg-white rounded-md border-solid shadow-md p-4 h-max max-w-sm 2xl:max-w-md'>
    <div className="flex gap-4 items-center">
        <div className="">
            <p className="text-md text-gray-700 mb-1 font-medium">Total Creators</p>
            {userCounts.total.creators > 0 ? (
                <>
                    <p className="text-xl mb-1 text-center tracking-widest">{userCounts.total.creators}</p>
                    <p className="text-sm text-center text-gray-700">
                        <span className='text-red-500'>5%</span> vs last month
                    </p>
                </>
            ) : (
                <p className="text-lg text-center text-gray-700">No Data Available</p>
            )}
        </div>
        {userCounts.total.creators > 0 && (
            <div className='h-24 flex items-center justify-center w-2/4 2xl:w-4/6'>
                <ReactECharts option={creatorOption} style={{ height: '100%', width: '100%' }} />
            </div>
        )}
    </div>
</div>

<div className='bg-white rounded-md border-solid shadow-md p-4 h-max max-w-sm 2xl:max-w-md'>
    <div className="flex gap-4 items-center">
        <div className="">
            <p className="text-md text-gray-700 mb-1 font-medium">Total Students</p>
            {userCounts.total.students > 0 ? (
                <>
                    <p className="text-xl mb-1 text-center tracking-widest">{userCounts.total.students}</p>
                    <p className="text-sm text-center text-gray-700">
                        <span className='text-green-500'>5%</span> vs last year
                    </p>
                </>
            ) : (
                <p className="text-lg text-center text-gray-700">No Data Available</p>
            )}
        </div>
        {userCounts.total.students > 0 && (
            <div className='h-24 flex items-center justify-center w-2/4 2xl:w-4/6'>
                <ReactECharts option={studentOption} style={{ height: '100%', width: '100%' }} />
            </div>
        )}
    </div>
</div>

<div className='bg-white rounded-md border-solid shadow-md p-4 h-max max-w-sm 2xl:max-w-md'>
    <div className="flex gap-4 items-center">
        <div className="">
            <p className="text-md text-gray-700 mb-1 font-medium">Total Mentors</p>
            {userCounts.total.mentors > 0 ? (
                <>
                    <p className="text-xl mb-1 text-center tracking-widest">{userCounts.total.mentors}</p>
                    <p className="text-sm text-center text-gray-700">
                        <span className='text-red-500'>5%</span> vs last month
                    </p>
                </>
            ) : (
                <p className="text-lg text-center text-gray-700">No Data Available</p>
            )}
        </div>
        {userCounts.total.mentors > 0 && (
            <div className='h-24 flex items-center justify-center w-2/4 2xl:w-4/6'>
                <ReactECharts option={mentorOption} style={{ height: '100%', width: '100%' }} />
            </div>
        )}
    </div>
</div>



            </div> */}

            {schoolDetails ? (
                <div className="mx-auto flex flex-row-reverse gap-4 h-full">
                    <div className="bg-white rounded-md border-solid shadow-md p-3 w-1/3 min-h-96">
                        <ReactECharts option={optionTwo} style={{ height: '100%', width: '100%' }} />

                    </div>

                    <div className="bg-white rounded-md border-solid shadow-md p-4 h-full w-2/3">
                        <div className="flex overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap">
                            <button
                                className={`inline-flex items-center h-10 px-4 -mb-px text-sm text-center ${selectedDetails === 'schoolDetails'
                                    ? 'text-blue-600 bg-transparent border-b-2 border-blue-500'
                                    : 'text-gray-700 bg-transparent border-b-2 border-transparent hover:border-gray-400'
                                    } sm:text-base whitespace-nowrap focus:outline-none`}
                                onClick={() => handleDetailsChange('schoolDetails')}
                            >
                                Oranization Details
                            </button>
                            <button
                                className={`inline-flex items-center h-10 px-4 -mb-px text-sm text-center ${selectedDetails === 'adminDetails'
                                    ? 'text-blue-600 bg-transparent border-b-2 border-blue-500'
                                    : 'text-gray-700 bg-transparent border-b-2 border-transparent hover:border-gray-400'
                                    } sm:text-base whitespace-nowrap cursor-base focus:outline-none`}
                                onClick={() => handleDetailsChange('adminDetails')}
                            >
                                Admin Details
                            </button>
                        </div>

                        <div className="overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap dark:border-gray-700">
                            <table className="w-full">
                                <tbody>
                                    {/* <!-- Entry 1 --> */}
                                    {selectedDetails === "schoolDetails" && (
                                        <>
                                            <tr className="flex text-start border-b border-gray-200 mt-2">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Oranization Name</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{organisation_name}", schoolDetails)}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 2 --> */}
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Oranization Address</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{organisation_address}", schoolDetails)}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 3 --> */}

                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>City</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{city}", schoolDetails)}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 4 --> */}

                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>State</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{state}", schoolDetails)}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 5 --> */}

                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Zip Code</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{zip_code}", schoolDetails)}
                                                </td>
                                            </tr>
                                            {/* <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Pricipal Name</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{principal_name}", schoolDetails)}
                                                </td>
                                            </tr> */}
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Contact Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{contact_number}", schoolDetails)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Alternative Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{school_alternative_number}", schoolDetails)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Document Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders(
                                                        "{document_number}",
                                                        schoolDetails
                                                    )}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Classes Available</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {formatClassIds(schoolDetails.available_classes)}
                                                </td>
                                            </tr>


                                        </>

                                    )}

                                    {selectedDetails === 'adminDetails' && (
                                        <>
                                            <tr className="flex text-start border-b border-gray-200 mt-2">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>First Name</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{first_name}", schoolDetails)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200 mt-2">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Middle Name</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{middle_name}", schoolDetails)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200 mt-2">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Last Name</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{last_name}", schoolDetails)}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 2 --> */}
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Birthdate</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{birthdate}", schoolDetails)}
                                                </td>
                                            </tr>
                                            {/* <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Aadharcard Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{aadharcard_number}", schoolDetails)}
                                                </td>
                                            </tr> */}
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Contact Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{contact_number}", schoolDetails)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Alternative Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{alternative_number}", schoolDetails)}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 3 --> */}

                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>City</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{admin_city}", schoolDetails)}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 4 --> */}

                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>State</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{admin_state}", schoolDetails)}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 5 --> */}

                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Zip Code</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                                                    {replacePlaceholders("{admin_zip_code}", schoolDetails)}
                                                </td>
                                            </tr>

                                        </>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>) : (
                <div>Loading</div>
            )}
        </>
    )
}

export default AdminHome;

