import { useState, useEffect } from "react";
import axios
    from "axios";
import { BASEURL } from "../../../../../constants";
const PcVideo = ({ setCurrentSlideData, videoData, setVideoData, reRenderChid, contentId }) => {

    const [videoFile, setVideoFile] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const handleFileChange = (e) => {
        setVideoFile(e.target.files[0]);
    };

    useEffect(() => {
        console.log(videoData, "VFFV FG")
        if (videoData.videoKey) {
            getUrl();
        }

    }, [])


    const getUrl = async () => {
        try {
            const response = await axios.get(`${BASEURL}/api/getVideoUrl`, {
                params: {
                    Key: contentId
                }
            });
            setVideoUrl(response.data.videoUrl);
            // console.log("Response", response);
        } catch (error) {
            console.error('Error uploading video:', error);
        }
    }
    const handleUpload = async () => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('video', videoFile);
        formData.append('key', contentId);
        try {
            const response = await axios.post(`${BASEURL}/api/video-upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setIsUploading(false);
            setVideoUrl(response.data.videoUrl);
            console.log("setting videoData", contentId)
            setVideoData((videoData) => {
                return { ...videoData, videoKey: contentId }
            })
            setCurrentSlideData((currentSlideData) => {
                return { ...currentSlideData, videoIdArray: currentSlideData.videoIdArray.includes(contentId) ? [...currentSlideData.videoIdArray] : [...currentSlideData.videoIdArray, contentId] }
            })
            // console.log("Response", response);
        } catch (error) {
            console.error('Error uploading video:', error);
        }
    };

    return (
        <div>
            {
                !videoData.videoKey && (
                    <>
                        <input type="file" accept="video/*" onChange={handleFileChange} />
                        <button className="px-3 py-1 border border-solid border-slate-400 rounded-md text-white bg-slate-500" onClick={handleUpload}>Upload Video</button>
                    </>
                )
            }
            {
                isUploading && (
                    <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                        <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                        <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                                <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">

                                    <div className="flex justify-center py-2 px-3">
                                        <span className='font-bold'>Uploading your video please wait...</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {videoUrl && (
                <div className="flex justify-center">
                    <video controls width="600">
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </div>
    );
}

export default PcVideo;