import React, { useEffect, useState } from 'react';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { Droppable } from './Droppable';
import { SortableItem } from './SortableItem/SortableItem';
import { Item } from './SortableItem/Item';
import Editor from './Editor_Component/Editor';
import VideoComponent from './Video_Component/VideoComponent';
import { v4 as uuidv4 } from 'uuid';
import McqComponent from './MCQ_Component/McqComponent';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { Resizable } from 're-resizable';
import axios from 'axios';
import { BASEURL } from '../../../../constants';
import Thumbnail from './Thumbnail';
import Draggable from './DraggableItems/Draggable';
import AiContentGenerator from './AiContentGenerator';
import { useParams } from 'react-router-dom';
import PowerPoint from './Ppt component/PowerPoint';
import SlideSortableItem from './SortableItem/SlideSortableItem';
// slide interchange branch 
const CourseCreator = ({ selectedChapterId, selectedQuizId, setSlideId, isDeleted, setIsDataSaved, setShowSaveDataModal,
    showSaveDataModal, isDataSaved, setImagesArray, imagesArray, handleSaveCourse, setSlidesArray, slidesArray,
    setCurrentSlideId, currentSlideId, currentSlideData, setCurrentSlideData,
    autoSaveSlide, selectedTopicId
}) => {
    const [showModal, setShowModal] = useState(false);
    const [deleteSlideId, setDeleteSlideId] = useState(null);
    const [activeId, setActiveId] = useState(null);
    const [showDeleteSlideModal, setShowDeleteSlideModal] = useState(false);
    const [isDragging, setIsDragging] = useState("");
    const [loadingImage, setLoadingImage] = useState(false);
    //below state is need to re-render nested components when sorting happens 
    const [isSorted, setIsSorted] = useState(false);

    const draggablesArray = [{ id: 'Heading', icon: 'fa-solid fa-heading' },
    { id: 'Text', icon: 'fa-regular fa-pen-to-square' },
    { id: 'Image', icon: 'fa-regular fa-image image' },
    { id: 'Quiz', icon: 'fa-solid fa-q' },
    { id: 'Video', icon: 'fa-solid fa-video' },
    { id: 'Ppt', icon: 'fa-regular fa-file-powerpoint' }
    ]

    const [draggbles, setDraggables] = useState(draggablesArray);
    //below useEffect stores currentSlideId value into parent so when user goes into preview we can show that exact slide in preview as well 
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const { courseId } = useParams();
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    function handleDragStart(event) {
        //console.log("drag start", event);
        //based on this we render the required overlay
        console.log('isDragging', event.active.id);
        setIsDragging(event.active.id);
    }

    function handleSortEnd(event, currentSlideId) {
        //console.log("sort start", event);
        if (event.over === null) return;
        const { active, over } = event;
        setCurrentSlideData((currentSlide) => {
            const oldIndex = currentSlide.content.findIndex(contentObject => contentObject.id === active.id);
            const newIndex = currentSlide.content.findIndex(contentObject => contentObject.id === over.id);

            const newContent = arrayMove(currentSlide.content, oldIndex, newIndex);

            return { ...currentSlide, content: newContent }

        })
        setActiveId(null);
        setIsSorted(!isSorted);
        setIsDataSaved(false);
    }

    function handleDragEnd(event) {
        //console.log("drag end", event)
        setIsDragging(null);
        if (event.over === null) return;
        //the droppable component is passed the silde Id i.e which slide we are currenty on 
        //we compare that id with the id's of all the slides in the parent state and update the content when matched
        if (event.over && event.over.id !== null) {

            setCurrentSlideData((currentSlideData) => {
                console.log('drag end', { ...currentSlideData, content: [...currentSlideData.content, { id: uuidv4(), type: event.active.id, data: event.active.id === "Image" ? { imgData: '', width: '800px', height: '500px', align: 'center' } : "" }] });
                return { ...currentSlideData, content: [...currentSlideData.content, { id: uuidv4(), type: event.active.id, data: event.active.id === "Image" ? { imgData: '', width: '800px', height: '500px', align: 'center' } : "" }] }
            })
        }
    }

    function addSlide() {
        //saving overlay and disable other buttons 
        if (!isDataSaved) {
            //save previous slide 
            autoSaveSlide().then((response) => {
                if (response.data.status === 'success') {
                    console.log('eval', slidesArray.length ? (slidesArray.length + 1) : 1)
                    axios.post(`${BASEURL}/api/add-slide`, {
                        subTopicId: selectedChapterId,
                        sequence: slidesArray.length ? (slidesArray[slidesArray.length - 1].sequence + 1) : 1,
                        courseId: courseId,
                        topicId: selectedTopicId
                    }).then((addSlideResponse) => {
                        //we have saved the previous slide we reflect it in slidesArray 
                        const createdSlide = addSlideResponse.data;
                        console.log("createdSlide", createdSlide);
                        setSlidesArray((slidesArray) => {
                            const newSlidesArray = slidesArray.map((slide) => {
                                if (slide.id === currentSlideData.id) {
                                    return { ...currentSlideData }
                                } else {
                                    return slide;
                                }
                            })
                            newSlidesArray.push(createdSlide);
                            return newSlidesArray;
                        })
                        //now we set the currentSlide to the new created slide
                        setCurrentSlideData(createdSlide);
                        setCurrentSlideId(createdSlide.id);
                        setImagesArray([])
                    })
                }
            })
        } else {
            //there are no changes in the previous slide we can directly add a new slide 
            console.log('here', selectedTopicId);
            axios.post(`${BASEURL}/api/add-slide`, {
                subTopicId: selectedChapterId,
                sequence: slidesArray.length ? (slidesArray[slidesArray.length - 1].sequence + 1) : 1,
                courseId: courseId,
                topicId: selectedTopicId
            }).then((response) => {
                //
                console.log('respinse', response, response.data)
                const createdSlide = response.data;

                setSlidesArray((slidesArray) => {
                    const newSlidesArray = [...slidesArray];
                    newSlidesArray.push(createdSlide);
                    return newSlidesArray;
                })
                //now we set the currentSlide to the new created slide
                setCurrentSlideData(createdSlide);
                setCurrentSlideId(createdSlide.id);
            })
        }
    }

    function handleSortStart(event) {
        const { active } = event;
        setActiveId(active.id)
        //console.log("sort end", event)
    }

    function paginate(id) {
        //we have the slide id we just need to set currentSlideData
        if (id === currentSlideData.id) {
            return;
        } else {



            const newSlide = slidesArray.find(slide => slide.id === id);
            //we need to save the previous slide 
            if (!isDataSaved) {
                autoSaveSlide().then((response) => {
                    if (response.data.status === 'success') {
                        setSlidesArray((slidesArray) => {
                            const newSlidesArray = slidesArray.map((slide) => {
                                if (slide.id === currentSlideData.id) {
                                    return { ...currentSlideData }
                                } else {
                                    return slide;
                                }
                            })
                            return newSlidesArray;
                        })
                        //now we set the currentSlide to the new created slide
                        setCurrentSlideData(newSlide);
                        setCurrentSlideId(id);
                        setImagesArray([])
                    }
                })
            } else {
                setCurrentSlideData(newSlide);
                setCurrentSlideId(id);
            }

        }
    }
    //takes slide id and content id ,adds e.target.value to the data property
    function handleOnChange(e, contentId, id) {
        setCurrentSlideData((currentSlideData) => {
            const newContentArray = currentSlideData.content.map((contentObject) => {
                if (contentObject.id === contentId) {
                    return {
                        id: contentObject.id,
                        type: contentObject.type,
                        data: e.target.value
                    }
                }
                return {
                    ...contentObject
                }
            })
            return { ...currentSlideData, content: newContentArray }
        })
    }

    function handleImageChange(event, slideId, contentId) {
        const image = event.target.files[0];
        const url = URL.createObjectURL(image);
        //console.log("---------------------------image onChange start---------------------------------------");
        // setImageIdArray((imageIdArray) => {
        //     const newImageIdArray = [...imageIdArray];
        //     console.log('upload image handler', newImageIdArray);

        //     newImageIdArray.push(contentId);
        //     console.log('upload image handler after push ', newImageIdArray);
        //     //console.log("ImageIdArray", newImageIdArray);
        //     return newImageIdArray;
        // });
        setImagesArray((imagesArray) => {
            //console.log("called setImages")
            const newImagesArray = imagesArray;
            newImagesArray.push({ id: contentId, data: image });
            //console.log("ImagesArray", newImagesArray);
            return newImagesArray;
        })
        setCurrentSlideData((currentSlide) => {
            const newContent = currentSlide.content.map((contentObject) => {
                if (contentObject.id === contentId) {
                    return {
                        id: contentObject.id,
                        type: contentObject.type,
                        data: { ...contentObject.data, imgData: url }
                    }
                }
                return {
                    ...contentObject
                }
            })
            console.log('imageIdArray', currentSlide, currentSlide.imageIdArray);
            const newImageIdArray = [...currentSlide.imageIdArray, contentId]
            console.log('new slide ', { ...currentSlide, content: newContent, imageIdArray: newImageIdArray })
            return { ...currentSlide, content: newContent, imageIdArray: newImageIdArray }
        });

        //console.log("---------------------------------------image onchange finish--------------------------------------------")
    }

    function handleResize(e, d, ref, delta, element) {
        setCurrentSlideData((currentSlide) => {
            const newContent = currentSlide.content.map((obj) => {
                if (obj.id === element.id) {
                    return {
                        ...obj, data: {
                            ...obj.data, width: ref.style.width,
                            height: ref.style.height,
                        }
                    }
                } else {
                    return {
                        ...obj
                    }
                }
            })
            return { ...currentSlide, content: newContent }
        })
    }

    function deleteSlide() {
        /* 
            1. make delete api call 
            2. depending on the case set states  
        */
        //
        if (currentSlideId === deleteSlideId) {
            if (slidesArray.length === 1) {
                //user is deleting the only slide present 
                //create a new slide 
                axios.delete(`${BASEURL}/api/delete-slide`, {
                    params: {
                        slideId: deleteSlideId
                    }
                }).then((response) => {
                    if (response.data.status === 'successfully deleted') {
                        //images and slide deleted create a new slide and add it to slidesArray as well as currentSlide
                        axios.post(`${BASEURL}/api/add-slide`, {
                            subTopicId: selectedChapterId,
                            sequence: 1,
                            courseId: courseId,
                            topicId: selectedTopicId
                        }).then((addSlideResponse) => {
                            //{slideId :  , imageIdArray: []} do we get the entire slide as response
                            setSlidesArray([addSlideResponse.data]);
                            setCurrentSlideData(addSlideResponse.data);
                            setCurrentSlideId(addSlideResponse.data.id);
                            setImagesArray([]);
                            setShowModal(false);
                            setDeleteSlideId(null);
                        })
                    }
                })
            } else {
                //if user is deleting last slide move backwards every other time move forwards
                if (slidesArray[slidesArray.length - 1].id === currentSlideId) {
                    //deleting the last slide move back 
                    axios.delete(`${BASEURL}/api/delete-slide`, {
                        params: {
                            slideId: deleteSlideId
                        }
                    }).then((response) => {
                        setSlidesArray((slidesArray) => {
                            const newSlidesArray = slidesArray.filter(slide => slide.id !== deleteSlideId);
                            return newSlidesArray;
                        })
                        //get the previous slide 
                        const previousSlide = slidesArray[slidesArray.length - 2];
                        console.log(previousSlide, previousSlide.id);
                        setCurrentSlideData(previousSlide);
                        setCurrentSlideId(previousSlide.id);
                        setImagesArray([]);
                        setShowModal(false);
                        setDeleteSlideId(null);
                    })
                } else {
                    //not the last slide delete and move forward.
                    axios.delete(`${BASEURL}/api/delete-slide`, {
                        params: {
                            slideId: deleteSlideId
                        }
                    }).then((response) => {
                        setSlidesArray((slidesArray) => {
                            const newSlidesArray = slidesArray.filter(slide => slide.id !== deleteSlideId);
                            return newSlidesArray;
                        })
                        //get the next slide 
                        const nextSlideIndex = slidesArray.findIndex(slide => slide.id === deleteSlideId) + 1;
                        const nextSlide = slidesArray[nextSlideIndex];
                        setCurrentSlideData(nextSlide);
                        setCurrentSlideId(nextSlide.id);
                        setImagesArray([]);
                        setShowModal(false);
                        setDeleteSlideId(null);
                    })
                }
            }
        } else {
            //dont match 
            axios.delete(`${BASEURL}/api/delete-slide`, {
                params: {
                    slideId: deleteSlideId
                }
            }).then((response) => {
                setSlidesArray((slidesArray) => {
                    const newSlidesArray = slidesArray.filter(slide => slide.id !== deleteSlideId);
                    return newSlidesArray;
                })
                setShowModal(false);
                setDeleteSlideId(null);
            })
        }
        //how is the data saved that this slide is deleted well they have to click on save
    }

    function handleAlign(e, element) {
        const selectedValue = e.target.value;
        setCurrentSlideData((currentSlide) => {
            const newContent = currentSlide.content.map((contentObj) => {
                if (contentObj.id === element.id) {
                    return {
                        ...contentObj, data: { ...contentObj.data, align: selectedValue }
                    }
                } else {
                    return contentObj;
                }
            })
            return { ...currentSlide, content: newContent }
        })
        // setSlidesArray((slidesArray) => {
        //     const newSlides = slidesArray.slides.map((slide) => {
        //         if (slide.id === currentSlideId) {
        //             return {
        //                 ...slide, content: slide.content.map((contentObj) => {
        //                     if (contentObj.id === element.id) {
        //                         return {
        //                             ...contentObj, data: { ...contentObj.data, align: selectedValue }
        //                         }
        //                     } else {
        //                         return contentObj;
        //                     }
        //                 })
        //             }
        //         } else {
        //             return slide;
        //         }
        //     })
        //     return { slides: newSlides }
        // })
    }

    function nextSlide() {
        //[{id}{id}{id}]
        const currentSlideIndex = slidesArray.findIndex((slide) => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex + 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex + 1]);
    }

    function previousSlide() {
        const currentSlideIndex = slidesArray.findIndex(slide => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex - 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex - 1]);

    }

    const disableStyle = {
        pointerEvents: "none",
        color: "grey"
    }

    function disableNext() {
        if (currentSlideId === slidesArray[slidesArray.length - 1].id) return disableStyle;
        return {}
    }

    function disablePrevious() {
        if (currentSlideId === slidesArray[0].id) return disableStyle;
        return {}
    }

    function handleOnChangeParentDiv() {
        console.log('testing even bubbling.')
    }

    function handleSlideDelete() {
        //we have the slideId in deleteSlideId state 
    }

    function handleDragOver({ action, over }) {
        console.log('drag over ', action, over)
    }

    function slideRearrange(event) {
        if (event.over === null) return;

        const { active, over } = event;

        const oldIndex = slidesArray.findIndex(slide => slide.id === active.id);

        const newIndex = slidesArray.findIndex(slide => slide.id === over.id);

        const newSlidesArray = arrayMove(slidesArray, oldIndex, newIndex);

        const seqArray = newSlidesArray.map((slide , index) => {
            return { slideId : slide.id , sequence : index + 1 }
        })


        console.log("new Slides array ", newSlidesArray);
        setSlidesArray(newSlidesArray);

        axios.post(`${BASEURL}/api/change-slide-sequence` , { seqArray : seqArray} );

    }

    return (<>
        {
            showModal &&
            <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                        <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">

                            <div className='flex w-full justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                </svg>
                            </div>
                            <div className="flex justify-center py-2 px-3">
                                <span className='font-bold'>Are you sure?</span>

                            </div>
                            <div className="flex justify-center py-2 px-3">
                                <span className='text-xs text-gray-600'>Do you really want to delete this?This action cannot be undone.</span>

                            </div>
                            <div className="flex justify-around">
                                <button className='px-3 py-1 border-2 border-gray-300 rounded-lg text-gray-500 text-xs' onClick={() => { setShowModal(false) }}>Cancel</button>
                                <button onClick={() => { deleteSlide() }} className='px-3 py-1 bg-slate-500 text-white rounded-lg text-xs'>Yes, Delete it!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            slidesArray ? (
                <div style={{ width: '85%' }} className='flex h-full overflow-auto overflow-x-hidden' onChange={handleOnChangeParentDiv}>
                    <DndContext onDragStart={handleDragStart} onDragOver={handleDragOver} onDragEnd={handleDragEnd} modifiers={[restrictToWindowEdges]}>
                        <div style={{ width: '94%' }} className='flex flex-col h-full'>
                            <div className='h-full overflow-auto py-0 px-4 relative'>
                                {
                                    <Droppable key={currentSlideId} id={currentSlideId} selectedQuizId={selectedQuizId} content={currentSlideData.content}>
                                        {/* <span style={{ textAlign: "right" }}>slide No: {slidesArray.slides.findIndex((slide) => slide.id === currentSlideId) + 1}</span> */}
                                        <DndContext onDragStart={handleSortStart} onDragEnd={(event) => handleSortEnd(event, currentSlideId)}>

                                            <SortableContext items={currentSlideData.content} strategy={verticalListSortingStrategy}>
                                                {
                                                    currentSlideData.content.map((element, index) => {
                                                        if (element.type === 'Heading') {
                                                            return <SortableItem key={element.id} setCurrentSlideData={setCurrentSlideData} setIsDataSaved={setIsDataSaved} imagesArray={imagesArray} setImagesArray={setImagesArray} element={element} setSlidesArray={setSlidesArray} slideId={currentSlideId} setIsSorted={setIsSorted}>
                                                                <div className='bg-white'>
                                                                    <input className='w-full outline-none text-black' style={{ fontSize: "20px", fontWeight: '400', lineHeight: '145%', width: '100%', color: 'black' }} type='text' value={element.data} onChange={(e) => { setIsDataSaved(false); handleOnChange(e, element.id, currentSlideId) }} placeholder='Heading...' ></input>
                                                                </div>
                                                            </SortableItem>;
                                                        }
                                                        if (element.type === 'Text') {
                                                            return <SortableItem key={element.id} setCurrentSlideData={setCurrentSlideData} setIsDataSaved={setIsDataSaved} imagesArray={imagesArray} setImagesArray={setImagesArray} element={element} setSlidesArray={setSlidesArray} slideId={currentSlideId} setIsSorted={setIsSorted}>
                                                                <Editor key={element.id} data={element.data} slideId={currentSlideId} contentId={element.id} isSorted={isSorted} setIsDataSaved={setIsDataSaved} setCurrentSlideData={setCurrentSlideData} />
                                                            </SortableItem>;
                                                        }
                                                        if (element.type === 'Image') {
                                                            return <SortableItem setCurrentSlideData={setCurrentSlideData} setIsDataSaved={setIsDataSaved} imagesArray={imagesArray} setImagesArray={setImagesArray} element={element} key={index} setSlidesArray={setSlidesArray} slideId={currentSlideId} setIsSorted={setIsSorted}>
                                                                {element.data.imgData ? (
                                                                    <div>
                                                                        <div><label className='text-xs'>Align: </label>
                                                                            <select className='text-xs' onChange={(e) => { handleAlign(e, element) }}>
                                                                                <option value='left' selected={element.data.align === 'left'}>Left</option>
                                                                                <option value='center' selected={element.data.align === 'center'}>Center</option>
                                                                                <option value='right' selected={element.data.align === 'right'}>Right</option>
                                                                            </select>
                                                                        </div>
                                                                        <div style={{ width: "100%", display: "flex", justifyContent: element.data.align ? element.data.align : 'center' }}>
                                                                            <Resizable
                                                                                size={{
                                                                                    width: element.data.width,
                                                                                    height: element.data.height
                                                                                }}
                                                                                maxWidth='100%'
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                }}
                                                                                lockAspectRatio={false}
                                                                                onResizeStop={(e, d, ref, delta) => handleResize(e, d, ref, delta, element)}
                                                                                className='border'
                                                                            >
                                                                                {
                                                                                    <img src={element.data.imgData} style={{ height: "100%", width: "100%" }}></img>
                                                                                }
                                                                            </Resizable>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {element.data.imgData ? null : <div style={{ width: "100%", height: "100px", textAlign: "center", display: "flex", border: "2px dashed #E5E4E2", borderRadius: '12px', justifyContent: "center", alignItems: "center" }}>
                                                                    <label className='w-full h-full cursor-pointer flex flex-col gap-1 items-center justify-center' htmlFor={`${element.id}`} ><i style={{ color: '#7393B3' }} className="fa-solid fa-file-image text-base"></i><span className='text-xs' style={{ color: "#7393B3" }}>upload image</span></label>
                                                                </div>}
                                                                <input type='file' accept='image/*' id={`${element.id}`} onChange={(event) => { setIsDataSaved(false); handleImageChange(event, currentSlideId, element.id) }} style={{ display: "none" }}></input>
                                                            </SortableItem>;
                                                        }
                                                        if (element.type === 'Video') {
                                                            return (
                                                                <SortableItem setCurrentSlideData={setCurrentSlideData} setIsDataSaved={setIsDataSaved} imagesArray={imagesArray} setImagesArray={setImagesArray} element={element} key={index} setSlidesArray={setSlidesArray} slideId={currentSlideId} setIsSorted={setIsSorted}>
                                                                    <VideoComponent key={element.id} setCurrentSlideData={setCurrentSlideData} slidesArray={slidesArray} setSlidesArray={setSlidesArray} slideId={currentSlideId} contentId={element.id} data={element.data} isSorted={isSorted} setIsDataSaved={setIsDataSaved} />
                                                                </SortableItem>
                                                            )
                                                        }
                                                        if (element.type === 'Quiz') {
                                                            console.log('parent data passed to mcq', element.data);
                                                            return (
                                                                <SortableItem setCurrentSlideData={setCurrentSlideData} setIsDataSaved={setIsDataSaved} imagesArray={imagesArray} setImagesArray={setImagesArray} element={element} key={index} setSlidesArray={setSlidesArray} slideId={currentSlideId} selectedQuizId={selectedQuizId} setIsSorted={setIsSorted}>
                                                                    <McqComponent key={element.id} imagesArray={imagesArray} setSlidesArray={setSlidesArray} slideId={currentSlideId} contentId={element.id} slidesArray={slidesArray} data={element.data} isSorted={isSorted} setIsDataSaved={setIsDataSaved} setImagesArray={setImagesArray} setCurrentSlideData={setCurrentSlideData} />
                                                                </SortableItem>
                                                            )
                                                        }
                                                        if (element.type === 'Ppt') {
                                                            return (
                                                                <SortableItem setCurrentSlideData={setCurrentSlideData} setIsDataSaved={setIsDataSaved} imagesArray={imagesArray} setImagesArray={setImagesArray} element={element} key={index} setSlidesArray={setSlidesArray} slideId={currentSlideId} selectedQuizId={selectedQuizId} setIsSorted={setIsSorted}>
                                                                    <PowerPoint key={element.id} setCurrentSlideData={setCurrentSlideData} setIsDataSaved={setIsDataSaved} contentId={element.id} contentObj={element} />
                                                                </SortableItem>
                                                            )
                                                        }
                                                    })
                                                }
                                            </SortableContext>
                                            <DragOverlay>
                                                {activeId ? <Item id={activeId}><i className="fa-solid fa-sort" style={{ fontSize: "30px" }}></i></Item> : null}
                                            </DragOverlay>
                                        </DndContext>
                                    </Droppable>
                                }
                            </div>
                            <div className='flex px-4 py-0 items-center justify-between'>

                                <div className='flex gap-x-3 w-full overflow-auto pt-2 pb-1'>
                                    <DndContext onDragEnd={slideRearrange}>
                                        <SortableContext items={slidesArray} strategy={horizontalListSortingStrategy}>
                                            {
                                                slidesArray.map((slide, slideIndex) => {
                                                    return <SlideSortableItem id={slide.id} >
                                                        <Thumbnail slide={slide} currentSlideId={currentSlideId} slideIndex={slideIndex} paginate={paginate} setShowModal={setShowModal} setDeleteSlideId={setDeleteSlideId} />
                                                    </SlideSortableItem>
                                                })
                                            }

                                        </SortableContext>

                                    </DndContext>
                                    {/* {
                                        slidesArray.map((slide, slideIndex) => {
                                            return <Thumbnail slide={slide} currentSlideId={currentSlideId} slideIndex={slideIndex} paginate={paginate} setShowModal={setShowModal} setDeleteSlideId={setDeleteSlideId} />
                                        })
                                    } */}
                                    <div onClick={addSlide} className='shrink-0 flex justify-center bg-white items-center rounded-md w-20 h-16 2xl:w-28 2xl:h-24' style={{ cursor: 'pointer', overflow: 'hidden', border: '1px solid gray' }}>+</div>
                                </div>
                                <div className='flex items-center '>
                                    <svg style={disablePrevious()} onClick={previousSlide} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                    </svg>
                                    <svg style={disableNext()} onClick={nextSlide} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '6%' }} className=''>
                            <div className='border border-solid border-gray-300 rounded-2xl py-1 bg-white'>
                                <div className='flex flex-col items-center justify-start gap-6 p-2 py-3 h-full'>
                                    <>
                                        {
                                            draggbles ? (
                                                draggbles.map((item, index) => {
                                                    console.log('item ', item)
                                                    return <Draggable key={index} data={item} />
                                                })
                                            ) : (null)
                                        }
                                        <div onClick={toggleSidebar} style={{ padding: '0rem 0.5rem', border: '1px solid black', borderRadius: '100%', cursor: 'pointer' }}>AI</div>
                                    </>
                                </div>
                            </div>
                        </div>
                        <DragOverlay>
                            {
                                isDragging === "Text" ? (
                                    <>
                                        <div className="flex justify-center bg-white border border-solid rounded text-center px-3 py-2 2xl:px-4 2xl:py-3 text-xs  relative cursor-pointer">
                                            <i className="fa-regular fa-pen-to-square"></i>
                                        </div>
                                    </>
                                ) : null
                            }
                            {
                                isDragging === "Heading" ? (
                                    <div className="flex justify-center bg-white border border-solid rounded text-center px-3 py-2 2xl:px-4 2xl:py-3 text-xs  relative cursor-pointer" >
                                        <i className="fa-solid fa-heading"></i>
                                    </div>
                                ) : null
                            }
                            {
                                isDragging === "Image" ? (
                                    <div className="flex justify-center bg-white border border-solid rounded text-center px-3 py-2 2xl:px-4 2xl:py-3 text-xs  relative cursor-pointer">
                                        <i className="fa-regular fa-image image"></i>
                                    </div>
                                ) : null
                            }
                            {
                                isDragging === "Quiz" ? (
                                    <div className="flex justify-center bg-white border border-solid rounded text-center px-3 py-2 2xl:px-4 2xl:py-3 text-xs  relative cursor-pointer">
                                        <i class="fa-solid fa-q"></i>
                                    </div>
                                ) : null
                            }
                            {
                                isDragging === "Video" ? (
                                    <div className="flex justify-center bg-white border border-solid rounded text-center px-3 py-2 2xl:px-4 2xl:py-3 text-xs  relative cursor-pointer">
                                        <i className="fa-solid fa-video"></i>
                                    </div>
                                ) : null
                            }
                            {
                                isDragging === "Ppt" ? (
                                    <div className="flex justify-center bg-white border border-solid rounded text-center px-3 py-2 2xl:px-4 2xl:py-3 text-xs  relative cursor-pointer">
                                        <i className="fa-regular fa-file-powerpoint"></i>
                                    </div>
                                ) : null
                            }
                        </DragOverlay>
                    </DndContext>
                    {isSidebarOpen && (
                        <AiContentGenerator toggleSidebar={toggleSidebar} />
                    )}
                </div>
            ) : <div style={{ width: '80%' }} className='content-note'></div>
        }
    </>);
}

export default CourseCreator;