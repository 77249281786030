import { jwtDecode } from "jwt-decode";


const StudentHome = () => {

    const token = localStorage.getItem('auth');

    const decoded = jwtDecode(token);
    const userRole = decoded.role;
    const userId = decoded.user_id;
    const schoolId = decoded.org_id;
    const classId = decoded.class_id;
    const firstName = decoded.first_name;
    const lastName = decoded.last_name;

    return (
        <div className="w-full h-full flex justify-center ">
            <div>
                <div className="text-xl text-center">Hello, {firstName}</div>
                <div className="text-xl">Have a great day learning!</div>
            </div>
        </div>
    );
}

export default StudentHome;