import { useState, useEffect } from "react";
import axios
    from "axios";
import { BASEURL } from "../../../../constants";

const PcVideoPreview = ({ videoData,  contentId }) => {

    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        console.log(videoData, "VFFV FG")
        if (videoData.videoKey) {
            getUrl();
        }

    }, [])


    const getUrl = async () => {
        try {
            const response = await axios.get(`${BASEURL}/api/getVideoUrl`, {
                params: {
                    Key: contentId
                }
            });
            setVideoUrl(response.data.videoUrl);
            // console.log("Response", response);
        } catch (error) {
            console.error('Error uploading video:', error);
        }
    }
    

    return (
        <div>
            {videoUrl && (
                <div className="flex justify-center">
                    <video controls width="600">
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </div>
    );
}

export default PcVideoPreview;