import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item } from './Item';
import axios from 'axios';
import { SlideItem } from './SlideItem';

export const SlideSortableItem = ({id , children}) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <SlideItem ref={setNodeRef} style={style} >
            <div className='text-xs' {...attributes} {...listeners}><i class="fa-solid fa-grip-lines-vertical"></i></div>
            <div>{children}</div>
        </SlideItem>
    );
}

export default SlideSortableItem;